import "./Header.css";
import siano from "../Media/GIF/siano.gif";

function Header() {
  return (
    <>
      <div className="Header">
        <div className="box-left">
          <h1>Cubigator</h1>
          <p>
            <p>
              Meet <strong>$CUB</strong> - the cutest alligator on the TON
              blockchain. Inspired by the popular Telegram sticker pack!{" "}
              <br></br> Cubigator is a community-driven project where we are all
              working on our bags. Experience top-tier liquidity and a super
              friendly atmosphere with us!{" "}
            </p>
          </p>
        </div>
        <div className="box-right">
          <img className="gif-side-header" src={siano}></img>
        </div>
      </div>
    </>
  );
}

export default Header;
