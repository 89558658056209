import "./Next.css";
import twitter from "../Media/LOGO/x.png";
import serduszko from "../Media/GIF/serduszko.gif";

function Next() {
  return (
    <>
      <div className="Next">
        <div className="box-next-left">
          <img src={serduszko} className="gif-next"></img>{" "}
          {/* <img src="https://picsum.photos/id/237/150/200"></img> */}
        </div>
        <div className="box-next-right">
          <h3>What's next?</h3>
          <p>
            The unique
            <strong> game</strong> is under development. More information soon.
          </p>
          <p>
            Follow us on
            <strong> X </strong>
            to be the first one to know.
          </p>
          <button onClick={() => window.open("https://t.me/CubigatorTon")}>
            FOLLOW
            <img
              class="socialIcon-inButton"
              width="25"
              height="25"
              src={twitter}
              alt="twitterLogo"
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default Next;
