import "./Together.css";

function Together() {
  return (
    <>
      <div className="Together">
        <div className="box">
          <p>
            We believe in the power of the $CUB alligator emoji. Telegram's
            founder, Pavel Durov, has frequently used the $CUB sticker in his
            posts. With an astonishing
            <strong> 700 billion </strong>
            stickers shared monthly on Telegram, transforming them into
            <strong> NFTs </strong>
            significantly expands our potential.
            <br></br> Whether you’re a seasoned crypto investor or new to the
            world of digital assets,
            <strong> $CUB </strong>
            presents an exciting opportunity to be part of something
            extraordinary.
          </p>
        </div>
      </div>
    </>
  );
}

export default Together;
