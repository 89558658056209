import "./BuyNow.css";
import dedust from "../Media/LOGO/dedust.png";

function BuyNow() {
  return (
    <>
      <div className="BuyNow">
        <div className="box-now">
          <div className="line-buy">
            <h2>Join the $CUB family </h2>
            <h2>and let's ride it together!</h2>
          </div>
          <button
            onClick={() =>
              window.open(
                "https://dedust.io/swap/TON/EQCXSMNX3OB5jPgOJkEzjYhm7FOBfkA3cg7paBqTYwzXrjIi"
              )
            }
          >
            BUY NOW
            <img
              class="socialIcon-inButton"
              width="25"
              height="25"
              src={dedust}
              alt="dedustLogo"
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default BuyNow;
