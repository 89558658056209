import "./Carousel.css";
import telegram from "../Media/LOGO/telegram.png";

import gif1 from "../Media/GIF/placzek.gif";
import gif2 from "../Media/GIF/autogif.gif";
import gif3 from "../Media/GIF/bulbul.gif";
import gif4 from "../Media/GIF/okejkagif.gif";
import gif5 from "../Media/GIF/plomiengif.gif";
import gif6 from "../Media/GIF/hulajnoga.gif";
import gif11 from "../Media/GIF/pliska.gif";

import gif7 from "../Media/GIF/smieszekgif.gif";
import gif8 from "../Media/GIF/konkuter.gif";
import gif9 from "../Media/GIF/kochasgif.gif";
import gif10 from "../Media/GIF/fakery.gif";

import React from "react";

function Carousel() {
  return (
    <>
      <div className="Carousel">
        <div className="box-carusell">
          <div className="carusell-img">
            <img className="gif-go" src={gif3}></img>
            <img className="gif-go" src={gif2}></img>
            <img className="gif-go" src={gif1}></img>
            <img className="gif-go" src={gif4}></img>
            <img className="gif-go" src={gif5}></img>
            <img className="gif-go" src={gif6}></img>

            <img className="gif-go" src={gif11}></img>

            <img className="gif-go" src={gif7}></img>
            <img className="gif-go" src={gif8}></img>
            <img className="gif-go" src={gif9}></img>
            <img className="gif-go" src={gif10}></img>
          </div>

          <div className="box-carusell-txt">
            <div className="line-2">
              <h3>Explore all the stickers: </h3>
              <button onClick={() => window.open("https://t.me/CubigatorTon")}>
                JOIN TELEGRAM
                <img
                  class="socialIcon-inButton"
                  width="25"
                  height="25"
                  src={telegram}
                  alt="telegramLogo"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carousel;
