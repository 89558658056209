import "./Tokenomic.css";
import placzek from "../Media/GIF/muskpekniety.gif";

function Tokenomic() {
  return (
    <>
      <div className="Tokenomic">
        <div className="box-tokenomic-left">
          <img className="gif-side-tockenomy" src={placzek}></img>
        </div>

        <div className="box-tokenomic-right">
          <h3>Tokenomic:</h3>
          <div className="line-base">
            <p>Total supply:</p>
            <p> 980 000 000 $CUB</p>
          </div>
          <div className="line-base">
            <p>Initial LP:</p>
            <p> 900 000 000 $CUB + 995 $TON</p>
          </div>

          <div className="line">
            <p>
              Total LP <strong> BURNED </strong>
            </p>
            <p
              className="link"
              onClick={() =>
                window.open(
                  "https://tonviewer.com/transaction/4d8c80b585ee1d95bb7e000f51c818c380e234c272558122c318140f03eeab12"
                )
              }
            >
              <u>trx link</u>
            </p>
          </div>
          <div className="line">
            <p>
              Dev tokens
              <strong> BURNED </strong>
            </p>
            <p
              className="link"
              onClick={() =>
                window.open(
                  "https://tonviewer.com/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c/jetton/EQCXSMNX3OB5jPgOJkEzjYhm7FOBfkA3cg7paBqTYwzXrjIi"
                )
              }
            >
              <u>trx link</u>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomic;
