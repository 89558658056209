import "./Adress.css";

function Adress(props) {
  return (
    <>
      <div className="Adress">
        <div className="box-addres">
          <h2>$CUB available exclusively on TON</h2>
          <div
            className="addres-line"
            title="Click to copy"
            onClick={props.handleClickVariant(
              "info",
              "Token contract copied to clipboard."
            )}
          >
            <p>Token address:</p>
            <p className="addresLine">
              EQCXSMNX3OB5jPgOJkEzjYhm7FOBfkA3cg7paBqTYwzXrjIi
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Adress;
