import "./App.css";
import React, { useRef } from "react";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";

import Header from "./COMPONENTS/Header.js";
import Together from "./COMPONENTS/Together.js";
import Adress from "./COMPONENTS/Adress.js";
import Tokenomic from "./COMPONENTS/Tokenomic.js";
import Carousel from "./COMPONENTS/Carousel.js";
import Next from "./COMPONENTS/Next.js";
import BuyNow from "./COMPONENTS/BuyNow.js";

import Footer from "./COMPONENTS/Footer.js";

import bgImage from "./Media/PNG/bg-picture.jpg";

function App() {
  const tokenContract = "EQCXSMNX3OB5jPgOJkEzjYhm7FOBfkA3cg7paBqTYwzXrjIi";
  const handleClickVariant = (variant, message) => () => {
    enqueueSnackbar(message, { variant });
    copyToClipboard();
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(tokenContract)
      .then(() => {
        console.log("Copied to clipboard:", tokenContract);
        // Optionally, you can show a success message or perform any other action
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        // Optionally, you can show an error message or perform any other action
      });
  };

  return (
    <>
      <SnackbarProvider
        action={(snackbarId) => (
          <button
            className="SnackbarProviderButton"
            onClick={() => closeSnackbar(snackbarId)}
          >
            ✖
          </button>
        )}
      />
      <img className="bgImage" src={bgImage} />
      {/* <img className="bgImage" src={bgImage1} /> */}
      <div className="App">
        <div className="Content">
          <Header />
          <Tokenomic />
          <Adress handleClickVariant={handleClickVariant} />
          <Together />
          <Carousel />
          <Next />
          <BuyNow />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default App;
